import React, {useEffect, useState} from 'react';

import '../../Custom.css'
import {confirmDialog, contentStyles, iconButtonStyles} from './style'

import {Modal} from '@fluentui/react/lib/Modal';
import {Separator} from '@fluentui/react/lib/Separator';
import {Dialog, DialogFooter, DialogType} from '@fluentui/react/lib/Dialog';
import {ProgressIndicator} from '@fluentui/react/lib/ProgressIndicator';

import {Icon, Link, MessageBar, MessageBarType, TextField, Stack, Text} from '@fluentui/react';

import {useTranslation, Trans} from 'react-i18next'
import {useForm} from "react-hook-form";
import HelpComponentRelative from '../Help/HelpComponentRelative'

import {DefaultButton, IconButton, PrimaryButton} from '@fluentui/react/lib/Button';
import {IIconProps} from '@fluentui/react/lib/Icon';
import Helper from "../../Helpers/Helper";
import {Buffer} from 'buffer';
import {saveAs} from "file-saver";
import {useBranding} from "../../hooks/useBranding";
import {emptyTemplate, parseTemplate} from "./ImportTemplate.data";
import AudienceService from "../../services/audience";

export interface IApplicationV1 {
    FileName: string;
    Id: number;
    Title: string;
    Description: string;
    Pages: string;
    Revision: string;
    Author: string;
    Keywords: string;
    Created: string;
    LastSave: string,
    Exported: string;
    Lists: string,
    Expressions: string
    Template: string;
    Protected: boolean;
    AppName: string;
    Version: string;
}

export interface IApplicationImport {
    Message: string;
    Description: string;
    Bag: string;
    Status: boolean;
}

/**
 * Component ImportTemplate render
 * @param props
 */

function ImportTemplate(props: any) {
    const {branding} = useBranding()
    const emptyApplicationJSON: IApplicationV1 = {
        AppName: "",
        Author: "",
        Created: "",
        Protected: false,
        Exported: "",
        Description: "",
        FileName: "",
        Keywords: "",
        LastSave: "",
        Lists: "",
        Expressions: "",
        Pages: "",
        Template: "",
        Title: "",
        Version: "",
        Revision: "",
        Id: -1
    }

    const [applicationFileObject, setApplicationFileObject] = useState(emptyApplicationJSON);
    const [applicationFileObjectTemplate, setApplicationFileObjectTemplate] = useState(emptyTemplate);
    const [ImportedItems, setImportedItems] = useState<IApplicationImport[]>([])
    const [isHiddenConfirmDialog, setIsHiddenConfirmDialog] = useState(true)
    const [isNotApplicationAllowed, setIsNotApplicationAllowed] = useState(true)
    const [SuccessMessage, setSuccessMessage] = useState("")
    const [ErrorMessage, setErrorMessage] = useState("")
    const [currentDownloadList, setCurrentDownloadList] = useState("")
    const [currentDownloadListName, setCurrentDownloadListName] = useState("")
    const [fileName, setFileName] = useState("")
    const [hiddenPasswordDialog, setHiddenPasswordDialog] = useState(true)
    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const [showSuccessMessage, setShowSuccessMessage] = useState(false)
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [passwordField, setPasswordField] = useState("")
    const [isFormInvalid, setIsFormInvalid] = useState(true)
    const [isImporting, setIsImporting] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isAsyncProcess, setIsAsyncProcess] = useState(false)
    const {reset, formState: {}} = useForm();
    const cancelIcon: IIconProps = {iconName: 'Cancel'};
    const {t} = useTranslation(['common', 'importComponent']);
    const modalProps = React.useMemo(
        () => ({
            isBlocking: true,
            styles: confirmDialog,
            dragOptions: undefined,
        }),
        [],
    );

    useEffect(() => {
        if (props.showImportTemplate) {
            loadSubscriptionTemplate().then()
        }
    }, [props.showImportTemplate])

    /**
     * Verify the MaxApplicationAllowed value
     */
    const loadSubscriptionTemplate = async () => {
        let result: boolean = false
        setIsNotApplicationAllowed(true)
        setIsLoading(true)
        await AudienceService.GetTemplateUsage()
            .then((data) => {
                if (data.Success) {
                    result = true
                    setIsNotApplicationAllowed(false)
                } else {
                    setErrorMessage(data.ExceptionMessage)
                    setShowErrorMessage(true)
                }
            })
            .catch((error) => {
                setErrorMessage(error)
                setShowErrorMessage(true)
            })
            .finally(() => {
                setIsLoading(false)
            })

        return result
    }

    const dialogPasswordProps = {
        type: DialogType.normal,
        title: t("ImportComponent.PasswordDialog title", {ns: 'importComponent'}),
        closeButtonAriaLabel: t("Common.Button.Cancel", {ns: 'common'}),
        subText: undefined
    };

    const dialogConfirmProps = {
        type: DialogType.normal,
        title: t("ImportComponent.ConfirmDialog title", {ns: 'importComponent'}),
        closeButtonAriaLabel: t("Common.Button.Cancel", {ns: 'common'}),
        subText: t("ImportComponent.ConfirmDialog subText", {ns: 'importComponent'})
    };

    /**
     * Dismiss the PasswordDialog
     */
    const dismissPasswordDialog = () => {
        reset()
        setPasswordField("");
        setHiddenPasswordDialog(true)
    }

    /**
     * Function to hide and clear form fields of the current modal
     * */
    const hideModal = () => {
        setShowSuccessModal(false)
        setSuccessMessage("")
        setErrorMessage("")
        setShowSuccessMessage(false)
        setShowErrorMessage(false)
        setIsImporting(false)
        setIsLoading(false)
        setIsNotApplicationAllowed(false)
        setIsFormInvalid(true)
        setApplicationFileObject(emptyApplicationJSON)
        setApplicationFileObjectTemplate(emptyTemplate)
        setPasswordField("");
        setHiddenPasswordDialog(true)
        setIsHiddenConfirmDialog(true)
        setCurrentDownloadList("")
        setCurrentDownloadListName("")
        setFileName("")
        setIsAsyncProcess(false)
        props.hideImportTemplate()
    }

    /**
     * Function to read the File stream. Check that is a valid Application exported file
     * @param file
     */
    const getBase64 = (file: File) => {
        let fileContent: string = ""
        let applicationObject: IApplicationV1 = {
            AppName: "",
            Author: "",
            Created: "",
            Protected: false,
            Exported: "",
            Description: "",
            FileName: "",
            Keywords: "",
            LastSave: "",
            Lists: "",
            Expressions: "",
            Pages: "",
            Template: "",
            Title: "",
            Version: "",
            Revision: "",
            Id: -1
        }
        let reader = new FileReader();
        reader.readAsText(file);
        reader.onload = function () {

            const arr = reader.result!.toString()

            try {
                fileContent = Buffer.from(arr, 'base64').toString('utf8')
                applicationObject = JSON.parse(fileContent)

                let Validator = require('jsonschema').Validator;
                let v = new Validator();

                let applicationSchema = {
                    "id": "/ApplicationV1",
                    "type": "object",
                    "properties": {

                        "FileName": {"type": "string"},
                        "Id": {"type": "number"},
                        "Title": {"type": "string"},
                        "Description": {"type": "string"},
                        "Pages": {"type": "string"},
                        "Revision": {"type": "string"},
                        "Author": {"type": "string"},
                        "Keywords": {"type": "string"},
                        "Created": {"type": "string"},
                        "LastSave": {"type": "string"},
                        "Exported": {"type": "string"},
                        "Lists": {"type": "string"},
                        "Expressions": {"type": "string"},
                        "Template": {"type": "string"},
                        "Protected": {"type": "boolean"},
                        "AppName": {"type": "string"},
                        "Version": {"type": "string"}
                    },
                    "required": ["FileName", "Id", "Title", "Description", "Pages", "Revision", "Author", "Keywords", "Exported", "Template", "Protected", "AppName", "Version"]
                };
                let resultValidation = v.validate(applicationObject, applicationSchema, {nestedErrors: true})

                if (resultValidation.valid) {
                    setApplicationFileObject(applicationObject)
                    setApplicationFileObjectTemplate(parseTemplate(applicationObject.Template));
                    setSuccessMessage(t("ImportComponent.Messages.ValidApplicationJSON", {
                        ns: 'importComponent'
                    }))
                    setShowSuccessMessage(true)
                    setIsFormInvalid(false)
                } else {
                    setErrorMessage(t("ImportComponent.Messages.InvalidApplicationJSON", {
                        ns: 'importComponent',
                        Errors: resultValidation.errors.toString()
                    }))
                    setShowErrorMessage(true)
                }

            } catch {
                setErrorMessage(t('ImportComponent.Messages.InvalidApplicationFormat', {ns: 'importComponent'}))
                setShowErrorMessage(true)
            }

        };
        reader.onerror = () => {
            setErrorMessage(t('ImportComponent.Messages.InvalidReadFile', {ns: 'importComponent'}))
            setShowErrorMessage(true)
        };
    }

    async function uploadFile(ev: React.ChangeEvent<HTMLInputElement>) {

        setSuccessMessage("")
        setErrorMessage("")
        setShowSuccessMessage(false)
        setShowErrorMessage(false)
        setIsFormInvalid(true)
        setFileName("")
        setApplicationFileObject(emptyApplicationJSON)

        if (ev.target.files != null && ev.target.files.length > 0) {
            let mimeType = ev.target.files![0].type;

            if (mimeType.indexOf("text/plain") >= 0) {
                let name = ev.target.files![0].name;
                setFileName(name);
                getBase64(ev.target.files![0])
            } else {
                setErrorMessage(t('ImportComponent.Messages.InvalidFileFormat', {ns: 'importComponent'}))
                setShowErrorMessage(true)
            }
        }
    }

    /**
     * Function to Import the Selected Application
     */

    const importApplication = async () => {
        const result = await loadSubscriptionTemplate()
        if (result) {

            setIsImporting(true)
            setErrorMessage("")
            setShowErrorMessage(false)
            setSuccessMessage("")
            setShowSuccessMessage(false)

            let clientDate = new Intl.DateTimeFormat("en-US", {
                    year: "numeric",
                    month: "short",
                    day: "2-digit",
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric'

                }
            ).format(new Date())

            let isJson: boolean = false;
            await Helper.postImportTemplate(passwordField, applicationFileObject, clientDate)
                .then((data) => {
                    if (data.success) {

                        try {
                            setImportedItems(JSON.parse(JSON.parse(data.result)));
                        } catch {
                            setImportedItems([])
                        }

                        hideModal()
                        setSuccessMessage(t("ImportComponent.Messages.ImportSuccessful", {
                            ns: 'importComponent',
                            applicationName: fileName
                        }))

                        const site = Helper.getSite()!
                       
                        if(data.bag === undefined || data.bag !== "email") {
                            setShowSuccessMessage(true)
                            props.reloadApp(site, applicationFileObjectTemplate.Category)
                        }
                        else {
                            setIsAsyncProcess(true)
                        }
                        setShowSuccessModal(true)
                    }

                })
                .catch(errors => {
                    setErrorMessage(errors.message)
                    setShowErrorMessage(true)
                })
                .finally(() => {
                        setIsImporting(false)
                    }
                )
        }
    }

    /**
     * Function to show the confirm Download dialog
     * @param list in CSV format
     * @param name of the list
     */
    const showConfirmDownloadDialog = (list: string, name: string): void => {
        setIsHiddenConfirmDialog(false)
        setCurrentDownloadList(list)
        setCurrentDownloadListName(name)
    }

    /**
     * Function to download the List
     */
    const downloadList = async () => {

        let blob = new Blob([currentDownloadList], {
            type: "text/plain;charset=utf-8"
        });
        let name = currentDownloadListName.replace(/([^a-z0-9()_\-+ ]+)/gi, '-');
        saveAs(blob, `${name}.csv`);
    }
    /**
     * OnClick Import Button
     * */
    const importOnCLick = () => {
        if (applicationFileObject.Protected) {
            setPasswordField("")
            setHiddenPasswordDialog(false)
        } else {
            importApplication().then()
        }
    }

    return (
        /*Modal*/
        <div>


            <Dialog
                hidden={isHiddenConfirmDialog}
                dialogContentProps={dialogConfirmProps}
                modalProps={modalProps}
                onDismiss={() => {
                    setIsHiddenConfirmDialog(true)
                }}
            >
                <DialogFooter>
                    <PrimaryButton onClick={() => {
                        setIsHiddenConfirmDialog(true);
                        downloadList().then(r => r)
                    }} text={t("Common.Button.Download", {ns: 'common'})}/>
                    <DefaultButton onClick={() => {
                        setIsHiddenConfirmDialog(true)
                    }} text={t("Common.Button.Cancel", {ns: 'common'})}/>
                </DialogFooter>
            </Dialog>

            <Dialog
                hidden={hiddenPasswordDialog}
                onDismiss={dismissPasswordDialog}
                dialogContentProps={dialogPasswordProps}
                modalProps={modalProps}
            >
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <form>
                            <TextField
                                label={t("ImportComponent.TextField Password", {ns: 'importComponent'})}
                                type="password"
                                name="passwordField"
                                maxLength={20}
                                onChange={(_, v) => {
                                    setPasswordField(v!);
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter" && passwordField.length > 0) {
                                        e.preventDefault();
                                        setHiddenPasswordDialog(true);
                                        importApplication().then()
                                    }
                                    if (e.key === "Enter" && passwordField.length === 0) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </form>
                    </div>
                </div>

                <DialogFooter>
                    <PrimaryButton onClick={() => {
                        setHiddenPasswordDialog(true);
                        importApplication().then()
                    }} text={t("Common.Button.Continue", {ns: 'common'})}/>
                    <DefaultButton onClick={dismissPasswordDialog} text={t("Common.Button.Cancel", {ns: 'common'})}/>
                </DialogFooter>
            </Dialog>


            <Modal
                isOpen={showSuccessModal}
                containerClassName={contentStyles(branding.theme).container}
                isBlocking={true}>
                <form>
                    {/*Header*/}
                    <div className={contentStyles(branding.theme).header}>
                        <span>{t("ImportComponent.Component Title", {ns: 'importComponent'})}</span>
                    </div>
                    {/*End Header*/}

                    <Separator/>


                    <div className={contentStyles(branding.theme).body}>
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">

                                {showSuccessMessage &&
                                    <MessageBar
                                        messageBarType={MessageBarType.success}
                                        isMultiline={true}
                                    >
                                        {SuccessMessage}
                                    </MessageBar>
                                }
                            </div>
                            <br/>
                            {/*First row*/}
                            <div className="ms-Grid-row">
                                {/*First column*/}
                                <div className="ms-Grid-col scrollVisible"
                                     style={{maxHeight: '40vh', overflow: "auto", overflowX: "hidden"}}>
                                    {isAsyncProcess ?
                                        <Stack horizontal>

                                            <Stack styles={{root: {alignItems: "center", padding: "0px 16px"}}}>
                                                <Icon iconName="ReminderTime"
                                                      styles={{root: {width: 32, height: 32, fontSize: "32px"}}}/>
                                            </Stack>
                                            <Stack grow={1}>
                                                <Text variant={"large"} block>
                                                    {t("ImportComponent.AsyncProcessTitle", {ns: 'importComponent'})}
                                                </Text>
                                                <Text variant={"medium"} block
                                                      styles={{root: {paddingTop: 8, whiteSpace: "pre-line"}}}>
                                                    <Trans>
                                                        {t("ImportComponent.AsyncProcessDescription", {ns: 'importComponent'})}
                                                    </Trans>
                                                </Text>
                                            </Stack>
                                        </Stack>
                                        :
                                        <>
                                            <ul className={contentStyles(branding.theme).importedList}>

                                                {ImportedItems.map((item, index) => {
                                                        return (
                                                            <li key={index}>
                                                                <Icon iconName={item.Status ? 'SkypeCircleCheck' : 'Info'}
                                                                      style={{
                                                                          color: item.Status ? 'green' : 'orange',
                                                                          marginRight: 8
                                                                      }}/>
                                                                {item.Message}
                                                                <span>{item.Description}</span>
                                                                {!item.Status && item.Bag != null && item.Bag.trim().length > 0 &&
                                                                    <Link onClick={() => {
                                                                        showConfirmDownloadDialog(item.Bag, item.Message)
                                                                    }} underline>
                                                                        &nbsp;Download list
                                                                    </Link>
                                                                }
                                                            </li>
                                                        )
                                                    }
                                                )}
                                            </ul>
                                        </>
                                    }
                                    
                                </div>
                                {/*End First column*/}
                            </div>
                        </div>
                    </div>

                    <Separator/>

                    {/*Footer*/}
                    <div className={contentStyles(branding.theme).footer}>
                        <DialogFooter>
                            <PrimaryButton onClick={async () => {
                                hideModal()
                                if(!isAsyncProcess) props.notificartions(t('text.success', {ns: 'workspace'}), "success");
                            }} text={t("Common.Button.Accept", {ns: 'common'})}
                                           title={t("Common.Button.Accept", {ns: 'common'})}/>
                        </DialogFooter>
                    </div>
                    {/*End Footer*/}
                </form>
            </Modal>


            <Modal
                isOpen={props.showImportTemplate}
                containerClassName={contentStyles(branding.theme).container}
                isBlocking={true}>
                <form>
                    {/*Header*/}
                    <div className={contentStyles(branding.theme).header}>
                        <span>{t("ImportComponent.Component Title", {ns: 'importComponent'})}</span>
                        <HelpComponentRelative
                            headline={t("ImportComponent.Component Help headline", {ns: 'importComponent'})}
                            text={t("ImportComponent.Component Help Text", {ns: 'importComponent'})}
                            right="50px"
                            top="15px"
                            location="importexport"
                        />
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={cancelIcon}
                            disabled={isImporting}
                            ariaLabel={t("Common.Close", {ns: 'common'})}
                            onClick={hideModal}
                        />
                    </div>
                    {/*End Header*/}

                    <Separator/>

                    {/*Body*/}
                    <div className={contentStyles(branding.theme).body}>
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                                {showErrorMessage &&
                                    <MessageBar
                                        messageBarType={MessageBarType.error}
                                        isMultiline={false}
                                        dismissButtonAriaLabel={t("Common.Button.Close", {ns: 'common'})}
                                        truncated={true}
                                        overflowButtonAriaLabel={t("Common.Button.See more", {ns: 'common'})}
                                    >
                                        {ErrorMessage}
                                    </MessageBar>
                                }
                                {showSuccessMessage &&
                                    <MessageBar
                                        messageBarType={MessageBarType.success}
                                        isMultiline={false}
                                        dismissButtonAriaLabel={t("Common.Button.Close", {ns: 'common'})}
                                        truncated={true}
                                        overflowButtonAriaLabel={t("Common.Button.See more", {ns: 'common'})}
                                    >
                                        {SuccessMessage}
                                    </MessageBar>
                                }
                            </div>
                            <br/>
                            {/*First row*/}
                            <div className="ms-Grid-row">
                                {/*First column*/}
                                <div className="ms-Grid-col">
                                    <p>
                                        {t("ImportComponent.Callout Import Description", {ns: 'importComponent'})}
                                    </p>
                                    <p>
                                        {/* <em>{t("ImportComponent.Callout Import Password", {ns: 'importComponent'})}</em>*/}
                                    </p>
                                </div>
                                {/*End First column*/}
                            </div>
                            {/*End First row*/}
                            <br/>
                            {/*Second row*/}
                            <div className="ms-Grid-row">

                                <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg4">
                                    <label htmlFor="file">
                                        <div
                                            className="divHover divToolBoxItem" style={{
                                                cursor: isImporting || isNotApplicationAllowed ? "wait" : "pointer",
                                            color: branding.theme.palette.white,
                                            backgroundColor: isImporting || isNotApplicationAllowed ?  branding.theme.palette.neutralQuaternary:  branding.theme.palette.themePrimary
                                        }}>{t('Common.Input.File', {ns: 'common'})}</div>
                                    </label>
                                    <input className="fileButton" id="file" type="file" name="file" accept={'.txt'}
                                           onChange={uploadFile} disabled={isImporting || isNotApplicationAllowed}/>
                                </div>
                                <div className="ms-Grid-col ms-sm6 ms-md8 ms-lg8">
                                    <TextField autoFocus value={fileName} readOnly={true}
                                               disabled={isNotApplicationAllowed}
                                               title={t('ImportComponent.TextField File', {ns: 'importComponent'})}
                                               placeholder={t('ImportComponent.TextField File', {ns: 'importComponent'})}/>
                                </div>
                            </div>
                            {/*End Second row*/}
                            <br/>
                            {/*Progress Indicator*/}
                            {(isImporting || isLoading) &&
                                <div className="ms-Grid-row">
                                    <ProgressIndicator
                                        label={isImporting ? t("ImportComponent.ProgressIndicator Title", {ns: 'importComponent'}) : t("text.spinnerVerifying", {ns: 'common'})}
                                        description={isImporting ? t("ImportComponent.ProgressIndicator Description", {ns: 'importComponent'}) : t("text.spinnerVerifying", {ns: 'common'})}/>
                                </div>
                            }
                            {/*End Progress Indicator*/}
                        </div>
                    </div>
                    {/*End Body*/}

                    <Separator/>

                    {/*Footer*/}
                    <div className={contentStyles(branding.theme).footer}>
                        <DialogFooter>
                            <PrimaryButton disabled={isImporting || isFormInvalid || isNotApplicationAllowed}
                                           onClick={importOnCLick}
                                           text={t("ImportComponent.Button Import", {ns: 'importComponent'})}
                                           title={t("ImportComponent.Button Import Title", {ns: 'importComponent'})}/>
                            <DefaultButton onClick={hideModal} text={t("Common.Button.Cancel", {ns: 'common'})}
                                           disabled={isImporting}
                                           title={t("Common.Button.Cancel", {ns: 'common'})}/>
                        </DialogFooter>
                    </div>
                    {/*End Footer*/}
                </form>
            </Modal>
        </div>
        /*End Modal*/
    )
}

export default ImportTemplate
